import { FC, ReactNode } from 'react';
import { Space as AntSpace } from 'antd';
import styled from 'styled-components';

interface TableActionsProps {
  children: ReactNode;
}

const TableActions: FC<TableActionsProps> = ({ children }) => {
  return <Space>{children}</Space>;
};

const Space = styled(AntSpace)`
  padding: 10px 0;
`;

export default TableActions;
