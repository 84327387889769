import { FC } from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import { useSideMenuHandler } from '@/routes';

const SideMenu: FC = () => {
  const { currentRoute, menuItems, handleChange, handleClick } = useSideMenuHandler();

  return (
    <MenuItems
      mode='inline'
      items={menuItems}
      openKeys={currentRoute.openKeys}
      selectedKeys={currentRoute.selectedKeys}
      onOpenChange={handleChange}
      onClick={handleClick}
    />
  );
};

const MenuItems = styled(Menu)`
  background: inherit;
  //height: calc(100vh - 32px);
`;

export default SideMenu;
