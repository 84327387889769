import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@/app/store';
import config from '@/config';
import { bindToken, buildQueryParams } from '@/services/utils';
import { getAllReq, getAllRes, getByIdRes, updateReq, importFileRes, importConfirmReq } from './service-mapping';

import type {
  GetAllReq,
  GetAllRes,
  GetAllApiRes,
  GetByIdReq,
  GetByIdRes,
  GetByIdApiRes,
  UpdateReq,
  UpdateRes,
  DeleteMultipleReq,
  DeleteMultipleRes,
  ImportFileReq,
  ImportFileRes,
  ImportFileApiRes,
  ImportConfirmReq,
  ImportConfirmRes,
  UploadAttachmentReq,
  UploadAttachmentRes,
  GetAttachmentsReq,
  GetAttachmentsRes,
} from './types';

const service = createApi({
  reducerPath: 'nzta-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders: (headers, { endpoint, getState }) => {
      bindToken(
        ['getAll', 'getById', 'update', 'deleteMultiple', 'importFile', 'importConfirm', 'uploadAttachments', 'getAttachments'],
        endpoint,
        headers,
        getState() as RootState
      );
    },
  }),
  tagTypes: ['getAll', 'getById', 'getCertificates', 'getAttachments'],
  endpoints: (builder) => ({
    getAll: builder.query<GetAllRes, GetAllReq>({
      query: (args) => {
        const { pagination } = args;
        const query = getAllReq(args);
        const queryParams = buildQueryParams({ query, pagination });

        return `/newzealand/nzta/?${queryParams}`;
      },
      transformResponse: (res: GetAllApiRes) => {
        return getAllRes(res);
      },
      providesTags: ['getAll'],
      keepUnusedDataFor: 0,
    }),
    getById: builder.query<GetByIdRes, GetByIdReq>({
      query: ({ params }) => {
        const { id } = params;
        return `/newzealand/nzta/${id}`;
      },
      transformResponse: (res: GetByIdApiRes) => {
        return getByIdRes(res);
      },
      providesTags: (_, __, { params }) => [{ type: 'getById', id: params.id }],
      keepUnusedDataFor: 0,
    }),
    update: builder.mutation<UpdateRes, UpdateReq>({
      query: (args) => {
        const { id } = args.params;
        const payload = updateReq(args);

        return {
          url: `/newzealand/nzta/${id}`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
    deleteMultiple: builder.mutation<DeleteMultipleRes, DeleteMultipleReq>({
      query: ({ body }) => {
        return {
          url: `/newzealand/nzta/multi`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['getAll'],
    }),
    importFile: builder.mutation<ImportFileRes, ImportFileReq>({
      query: ({ body }) => ({
        url: `/generic/import/upload/japan?type=NZTA`,
        method: 'POST',
        body,
      }),
      transformResponse: (res: ImportFileApiRes) => {
        return importFileRes(res);
      },
    }),
    importConfirm: builder.mutation<ImportConfirmRes, ImportConfirmReq>({
      query: (args) => {
        const payload = importConfirmReq(args);
        return {
          url: `/generic/import/japan`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['getAll'],
    }),
    uploadAttachments: builder.mutation<UploadAttachmentRes, UploadAttachmentReq>({
      query: ({ params, body }) => ({
        url: `/newzealand/nzta/${params.id}/file/upload`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAttachments'],
    }),
    getAttachments: builder.query<GetAttachmentsRes, GetAttachmentsReq>({
      query: ({ params }) => `/newzealand/nzta/${params.id}/attachment`,
      providesTags: ['getAttachments'],
      keepUnusedDataFor: 0,
    }),
  }),
});

const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,

  getAll: service.useGetAllQuery,
  getById: service.useGetByIdQuery,
  update: service.useUpdateMutation,
  deleteMultiple: service.useDeleteMultipleMutation,
  importFile: service.useImportFileMutation,
  importConfirm: service.useImportConfirmMutation,
  uploadAttachments: service.useUploadAttachmentsMutation,
  getAttachments: service.useGetAttachmentsQuery,
};

export default serviceConfig;
