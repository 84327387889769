import React, { FC } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { App, ConfigProvider, theme } from 'antd';
import { initializeApp } from 'firebase/app';
import config from './config';
import { store } from './app/store';
import { useRoutes } from './routes';

import reportWebVitals from './reportWebVitals';
import worker from './worker';

import { useHandler, useServiceHandler } from './handler';

initializeApp(config.firebase);

const MainApp: FC = () => {
  const { isLoading, appTheme = 'light' } = useHandler();
  const router = useRoutes();

  const themeConfig = {
    dark: {
      algorithm: theme.darkAlgorithm,
      token: {
        colorPrimary: '#1dc976',
      },
    },
    light: {
      algorithm: theme.defaultAlgorithm,
      token: {
        colorPrimary: '#1ab269',
      },
    },
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: themeConfig[appTheme].algorithm,
        token: {
          controlHeight: 40,
          fontFamily: 'Open Sans, sans-serif',
          ...themeConfig[appTheme].token,
        },
        components: {
          Menu: {
            controlHeightLG: 35,
          },
        },
      }}
    >
      <App>{isLoading ? <>Loading...</> : <>{router}</>}</App>
    </ConfigProvider>
  );
};

const BgService = () => {
  useServiceHandler();
  return <></>;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BgService />
      <MainApp />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
worker();
