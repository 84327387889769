import type { CreateReq, GetAllRes, GetAllApiRes } from './types';

export const createReq = ({ body }: CreateReq) => {
  return {
    request_facility_id: body.requestFacilityId,
    request_date_1: body.requestDate1,
    request_date_2: body.requestDate2,
    destination_country: body.destinationCountry,
    shipper_name: body.shipperName,
    shipper_address: body.shipperAddress,
    shipper_telephone: body.shipperTelephone,
    shipper_fax: body.shipperFax,
    shipper_email: body.shipperEmail,
    shipper_notes: body.shipperNotes,
    applicant_name: body.applicantName || body.shipperName,
    applicant_address: body.applicantAddress || body.shipperAddress,
    applicant_telephone: body.applicantTelephone || body.shipperTelephone,
    applicant_fax: body.applicantFax || body.shipperFax,
    applicant_email: body.applicantEmail || body.shipperEmail,
    applicant_notes: body.applicantNotes || body.applicantNotes,

    vehicles: body.vehicles.map((vehicle) => ({
      make: vehicle.make,
      model: vehicle.model,
      chassis_no: vehicle.chassisNo,
      odometer_reading: Number(vehicle.odometerReading),
      export_certificate: vehicle.exportCertificate_value,
      payment_receipt: vehicle.paymentReceipt_value,
      permission_letter: vehicle.permissionLetter_value,
    })),
  };
};

export const getAllRes = (response: GetAllApiRes): GetAllRes => {
  return {
    meta: {
      current: response.data.page,
      pageSize: response.data.limit,
      total: response.data.total_rows,
    },
    results: response.data.rows.map((row) => ({
      uuid: row.uuid,
      requestDate1: row.request_date_1,
      requestFacilityName: row.branch_name,
      destinationCountry: row.destination_country,
      shipperName: row.shipper_name,
      vehiclesCount: row.vehicles_count,
    })),
  };
};
