import { useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectAuth, setSession } from '@/slices/session';
import service from '../service';

export const useAuth = () => {
  const dispatch = useAppDispatch();

  const authExp = useAppSelector(selectAuth)?.expirationTime;
  const [refreshAction] = service.refresh();
  const [logoutAction, { isSuccess: logoutSuccess }] = service.logout();

  const onAuthChange = useCallback(() => {
    const auth = getAuth();

    return onAuthStateChanged(auth, (user: any) => {
      if (user) {
        user.getIdToken(true);
        dispatch(
          setSession({
            user: {
              displayName: user?.displayName,
              email: user?.email,
            },
            auth: {
              token: user?.accessToken,
              expirationTime: user?.stsTokenManager?.expirationTime,
            },
          })
        );
      }
    });
  }, [dispatch]);

  const refreshToken = useCallback(() => {
    refreshAction();
  }, [refreshAction]);

  const logout = useCallback(logoutAction, [logoutAction]);

  return {
    authExp,
    logoutSuccess,

    onAuthChange,
    refreshToken,
    logout,
  };
};
