import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@/app/store';
import config from '@/config';
import { bindToken, buildQueryParams } from '@/services/utils';
import { createReq, getAllRes } from './service-mapping';

import type { CreateReq, CreateRes, GetAllReq, GetAllApiRes, GetAllRes, UploadFileReq, UploadFileRes } from './types';

const service = createApi({
  reducerPath: 'inspection-request-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders: (headers, { endpoint, getState }) => {
      bindToken(['getAll'], endpoint, headers, getState() as RootState);
    },
  }),
  endpoints: (builder) => ({
    create: builder.mutation<CreateRes, CreateReq>({
      query: (args) => {
        const payload = createReq(args);

        return {
          url: '/inspection-request/',
          method: 'POST',
          body: payload,
        };
      },
    }),
    getAll: builder.query<GetAllRes, GetAllReq>({
      query: ({ pagination }) => {
        const queryParams = buildQueryParams({ pagination });
        return `/inspection-request/?${queryParams}`;
      },
      transformResponse: (res: GetAllApiRes) => {
        return getAllRes(res);
      },
      keepUnusedDataFor: 0,
    }),
    uploadFile: builder.mutation<UploadFileRes, UploadFileReq>({
      query: (args) => {
        const { body } = args;
        return {
          url: `/inspection-request/file/upload`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,

  create: service.useCreateMutation,
  getAll: service.useGetAllQuery,
  uploadFile: service.useUploadFileMutation,
};

export default serviceConfig;
