import { RootState } from '@/app/store';

interface QueryAttributes {
  query?: {
    [key: string]: string | number | boolean | undefined;
  };
  pagination?: {
    page: number;
    limit: number;
  };
}

interface QueryOptions {
  keepUndefined?: boolean;
}

interface DateObj {
  year: string;
  month: string;
  date: string;
}

export const getSession = () => {
  let session = null;

  for (let key in sessionStorage) {
    if (key.startsWith('firebase:authUser:')) {
      const sessionString = sessionStorage.getItem(key);
      session = JSON.parse(sessionString || '{}');
      break;
    }
  }

  return session;
};

export const lsToken = () => {
  const session = getSession();
  let token = null;

  if (session) {
    const expirationTime = session?.stsTokenManager?.expirationTime;
    const now = new Date().getTime();

    if (expirationTime > now) {
      token = session?.stsTokenManager?.accessToken;
    }
  }

  return token;
};

export const bindToken = (authEndpoints: Array<string>, endpoint: string, headers: Headers, state: RootState): undefined | Headers => {
  if (authEndpoints.includes(endpoint)) {
    const token = state.sessionSlice.auth?.token;
    if (token) {
      headers.set('firebaseToken', token);
    } else {
      headers.set('firebaseToken', lsToken());
    }

    return headers;
  }
};

export const buildQueryParams = (attributes: QueryAttributes, options: QueryOptions = {}): string => {
  const { query, pagination } = attributes;
  const { keepUndefined = false } = options;

  const toMap = { ...query, ...pagination };
  return Object.entries(toMap)
    .filter((props) => (!keepUndefined && typeof props[1] !== 'undefined') || keepUndefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
};

export const getDateObj = (sDate: string): DateObj => {
  const instance = new Date(sDate);

  const year = instance.getFullYear().toString();
  const month = (instance.getMonth() + 1).toString().padStart(2, '0');
  const date = instance.getDate().toString().padStart(2, '0');

  return {
    year,
    month,
    date,
  };
};

export const getDateStr = (sDate: string): string => {
  const instance = new Date(sDate);

  const year = instance.getFullYear();
  const month = (instance.getMonth() + 1).toString().padStart(2, '0');
  const date = instance.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${date}`;
};

export const getDateTimeStr = (sDate: string): string => {
  const instance = new Date(sDate);

  const year = instance.getFullYear();
  const month = (instance.getMonth() + 1).toString().padStart(2, '0');
  const date = instance.getDate().toString().padStart(2, '0');

  const hour = instance.getHours().toString().padStart(2, '0');
  const minutes = instance.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${date} ${hour}:${minutes}`;
};
