import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { setSession } from '@/slices/session';
import type { LoginRes, LoginReq } from './types';

const service = createApi({
  reducerPath: 'auth-api',
  baseQuery: (f) => f(),
  endpoints: (builder) => ({
    login: builder.mutation<LoginRes, LoginReq>({
      query: (params) => async () => {
        try {
          const auth = getAuth();
          await setPersistence(auth, browserSessionPersistence);
          const response = await signInWithEmailAndPassword(auth, params.email, params.password);
          return {
            data: response.user.uid,
          };
        } catch (error) {
          return {
            error: 'Email or password incorrect. Please try again.',
          };
        }
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => async () => {
        try {
          await signOut(getAuth());
          return {
            data: null,
          };
        } catch (error) {
          return {
            error: 'An error occurred. Please try again.',
          };
        }
      },
      onQueryStarted: async (_, { dispatch }) => {
        await dispatch(
          setSession({
            user: null,
            auth: null,
          })
        );
        window.location.reload();
      },
    }),

    refresh: builder.mutation<void, void>({
      query: () => () => {
        getAuth().currentUser?.getIdToken(true);
        return { data: null };
      },
    }),
  }),
});

const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,
  endpoints: service.endpoints,

  login: service.useLoginMutation,
  logout: service.useLogoutMutation,
  refresh: service.useRefreshMutation,
};

export default serviceConfig;
