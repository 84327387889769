import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '@/config';
import { trackByChassisNoRes, trackByIdRes, verifyCertificateRes } from './service-mapping';

import type {
  TrackByChassisNoReq,
  TrackByChassisNoRes,
  TrackByChassisNoApiRes,
  TrackByIdReq,
  TrackByIdRes,
  TrackByIdApiRes,
  VerifyCertificateReq,
  VerifyCertificateRes,
  VerifyCertificateApiRes,
} from './types';

const service = createApi({
  reducerPath: 'inspections-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
  }),
  tagTypes: ['create', 'getAll', 'getById', 'update', 'generateCertificates', 'getCertificates'],
  endpoints: (builder) => ({
    trackByChassisNo: builder.query<TrackByChassisNoRes, TrackByChassisNoReq>({
      query: (args) => {
        const { destination, chassisNo } = args.params;
        return `/certificate/status/${destination}/${chassisNo}`;
      },
      transformResponse: (res: TrackByChassisNoApiRes) => {
        return trackByChassisNoRes(res);
      },
      keepUnusedDataFor: 0,
    }),
    trackById: builder.query<TrackByIdRes, TrackByIdReq>({
      query: (args) => {
        const { destination, id } = args.params;
        return `/certificate/info/${destination}/${id}`;
      },
      transformResponse: (res: TrackByIdApiRes, meta, args) => {
        return trackByIdRes(res, args);
      },
      keepUnusedDataFor: 0,
    }),
    verifyCertificate: builder.query<VerifyCertificateRes, VerifyCertificateReq>({
      query: (args) => {
        const { destination, id } = args.params;
        return `/certificate/verify/${destination}/${id}`;
      },
      transformResponse: (res: VerifyCertificateApiRes, meta, args) => {
        return verifyCertificateRes(res, args);
      },
      keepUnusedDataFor: 0,
    }),
    verifyNCR: builder.query<VerifyCertificateRes, VerifyCertificateReq>({
      query: (args) => {
        const { destination, id } = args.params;
        return `/certificate/verify/ncr/${destination}/${id}`;
      },
      transformResponse: (res: VerifyCertificateApiRes, meta, args) => {
        return verifyCertificateRes(res, args);
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,

  trackByChassisNo: service.useTrackByChassisNoQuery,
  trackById: service.useTrackByIdQuery,
  verifyCertificate: service.useVerifyCertificateQuery,
  verifyNCR: service.useVerifyNCRQuery,
};

export default serviceConfig;
