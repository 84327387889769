import { useCallback, useState } from 'react';
import config from '@/config';
import { lsToken } from '../utils';

import type { ExportProps, Status } from './types';

const initialState: Status = {
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const useDownloadFile = () => {
  const action = useCallback((url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, []);

  return [action] as const;
};

export const useExportFile = () => {
  const [status, setStatus] = useState<Status>(initialState);

  const action = (props: ExportProps) => {
    const { method, endpoint, meta, fileName } = props;
    const payload: RequestInit = {
      method,
      headers: {
        firebaseToken: lsToken(),
      },
    };

    if (meta.body) {
      payload.body = JSON.stringify(meta.body);
    }

    let url = `${config.api.baseUrl}${endpoint}`;
    if (meta.query) {
      url += `?${meta.query}`;
    }

    setStatus({ isLoading: true, isSuccess: false, isError: false });

    fetch(url, payload)
      .then(async (res) => {
        if (!res.ok) {
          setStatus({ isLoading: false, isSuccess: false, isError: true });
          return;
        }

        const result = await res.json();

        const link = document.createElement('a');
        link.href = result.data.url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);

        setStatus({ isLoading: false, isSuccess: true, isError: false });
      })
      .catch(() => {
        setStatus({ isLoading: false, isSuccess: false, isError: true });
      });
  };

  return [action, status] as const;
};

export const useFetchAndDownload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = lsToken();

  const downloadFile = async (certificate: string, id: string) => {
    try {
      setLoading(true);
      const response = await fetch(`${config.api.baseUrl}/file/${certificate}/${id}`, {
        headers: {
          firebaseToken: token,
        },
      });
      const result = await response.json();

      if (result?.data?.url) {
        window.open(result.data.url, '_blank');
      }

      // const downloadUrl = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = downloadUrl;
      // // a.download = fileName;
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(downloadUrl);
      // setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  return { loading, error, downloadFile };
};
