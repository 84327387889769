import { FC } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const FreeLayout: FC = () => {
  return (
    <Layout>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

const Content = styled(Layout.Content)`
  min-height: 100vh;
`;

export default FreeLayout;
