import { useEffect, useState } from 'react';
import { useAuth } from '@/features/auth/hooks';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { selectTheme } from './slices/session';
import { useMyPermissions } from '@/features/roles/actions';

export const useHandler = () => {
  const appTheme = useAppSelector(selectTheme);
  const { isLoading } = useMyPermissions();
  return {
    isLoading,
    appTheme,
  };
};

export const useServiceHandler = () => {
  const [gainFocus, setGainFocus] = useState(false);
  const dispatch = useAppDispatch();
  const { authExp, onAuthChange, refreshToken } = useAuth();

  useEffect(() => {
    const unsubscribe = onAuthChange();
    return () => unsubscribe();
  }, [dispatch, onAuthChange]);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined;

    if (authExp) {
      interval = setInterval(refreshToken, 1000 * 60 * 15);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [authExp, refreshToken]);

  // useEffect(() => {
  //   let idleTimeout: NodeJS.Timer | undefined = undefined;
  //
  //   const resetIdleTimeout = () => {
  //     if (idleTimeout) {
  //       if (!gainFocus) {
  //         setGainFocus(true);
  //       }
  //       clearTimeout(idleTimeout);
  //     }
  //
  //     idleTimeout = setTimeout(() => {
  //       if (gainFocus) {
  //         setGainFocus(false);
  //       }
  //     }, 5000);
  //   };

  // window.addEventListener('focus', refreshToken);
  // window.addEventListener('mousemove', resetIdleTimeout);

  // return () => {
  // window.removeEventListener('focus', refreshToken);
  // window.removeEventListener('mousemove', resetIdleTimeout);
  //   };
  // }, [gainFocus, refreshToken, setGainFocus]);

  useEffect(() => {
    if (authExp) {
      refreshToken();
    }
  }, [authExp, refreshToken]);
};
