import { FC, ReactNode } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { Container } from '..';
import { useOpenHandler } from './handler';

interface PageProps {
  tabName?: string;
  cover?: string;
  title: string;
  children: ReactNode;
}

const OpenPage: FC<PageProps> = ({ tabName, cover, title, children }) => {
  useOpenHandler({ tabName });

  return (
    <>
      {cover && <Cover $url={cover} />}
      <Container>
        <Title level={4}>{title}</Title>
        {children}
      </Container>
    </>
  );
};

const Cover = styled.div<{ $url: string }>`
  background-image: url(${(props) => props.$url});
  background-size: contain;
  height: 30vh;
  background-position: center;
`;

const Title = styled(Typography.Title)`
  margin-bottom: 30px !important;
`;

export default OpenPage;
