import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import FormSketch from './FormSketch';

import type { CFormItemProps, TabFormProps, TabFormContentProps, FormValidateError } from './types';

const FormContent: FC<TabFormContentProps> = ({
  instance,
  isSubmitting,
  layout,
  size,

  initialValues,
  items,

  previousKey,
  nextKey,
  onFieldsChange,
  onAction,
}) => {
  return (
    <Form form={instance} layout={layout || 'vertical'} {...size} initialValues={initialValues} onFieldsChange={onFieldsChange}>
      <FormSketch items={items as CFormItemProps[]} />
      <ActionRow justify='space-between'>
        <ActionsWrapper span={24}>
          {previousKey && (
            <Button type='default' onClick={() => onAction(previousKey)}>
              Back
            </Button>
          )}
          <Button type='primary' loading={isSubmitting} onClick={() => onAction(nextKey)}>
            {nextKey ? 'Next' : 'Submit'}
          </Button>
        </ActionsWrapper>
      </ActionRow>
    </Form>
  );
};
const ActionRow = styled(Row)`
  margin-top: 20px;
`;
const ActionsWrapper = styled(Col)`
  button:last-child {
    float: right;
  }
`;

const TabForm: FC<TabFormProps> = ({ form, isFetching, isSubmitting, destroy, tabs, data, onFieldsChange, onFinish }) => {
  const [current, setCurrent] = useState(tabs[0].key);

  let [formInstance] = Form.useForm();
  if (form) {
    formInstance = form;
  }

  const keys = useMemo(() => tabs.map(({ key }) => key), [tabs]);
  const formKeys: any = useMemo(
    () =>
      tabs.reduce((acc, c) => {
        const keyMap = {};
        c.form.items.forEach((item) => {
          Object.assign(keyMap, { [item.name]: c.key });
        });

        return Object.assign(acc, keyMap);
      }, {}),
    [tabs]
  );

  const onAction = useCallback(
    async (nextCurrent?: string) => {
      try {
        if (nextCurrent) {
          setCurrent(nextCurrent);
        } else {
          await formInstance.validateFields();
          onFinish(formInstance.getFieldsValue());
        }
      } catch (error) {
        const { errorFields } = error as FormValidateError;
        const errorTab = formKeys[errorFields[0].name];

        if (current !== errorTab) {
          setCurrent(errorTab);
        }
      }
    },
    [current, formKeys, formInstance, onFinish, setCurrent]
  );

  useEffect(() => {
    if (destroy) {
      formInstance.resetFields();
      setCurrent(tabs[0].key);
    }
  }, [destroy, formInstance, tabs, setCurrent]);

  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue(data);
    }
  }, [data, formInstance]);

  const items = useMemo(
    () =>
      tabs.map(({ key, label, ...props }, index) => ({
        forceRender: true,
        key,
        label,
        children: (
          <FormContent
            {...props.form}
            instance={formInstance}
            isSubmitting={isSubmitting}
            previousKey={keys[index - 1]}
            nextKey={keys[index + 1]}
            onAction={onAction}
            onFieldsChange={onFieldsChange}
          />
        ),
      })),
    [tabs, keys, isSubmitting, formInstance, onAction, onFieldsChange]
  );

  return <>{isFetching ? <Skeleton /> : <Tabs tabPosition='left' activeKey={current} items={items} onTabClick={onAction} />}</>;
};

export default TabForm;
