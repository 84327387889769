import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const useDashboardHandler = ({ tabName }: { tabName?: string }) => {
  const navigation = useNavigate();

  useEffect(() => {
    document.title = tabName || 'ATJ Admin';
  }, [tabName]);

  const handleBack = () => {
    navigation(-1);
  };

  return {
    handleBack,
  };
};

export const useOpenHandler = ({ tabName }: { tabName?: string }) => {
  useEffect(() => {
    document.title = tabName || 'ATJ Admin';
  }, [tabName]);
};
