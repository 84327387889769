import { useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, RouterProvider, useLocation, useNavigate } from 'react-router-dom';
import { FileTextOutlined, LockOutlined, SendOutlined, DashboardOutlined } from '@ant-design/icons';
import { FreeLayout, OpenLayout, MainLayout } from '@/layouts';
import { isPermitted } from '@/helpers/permissionsHelper';
import { useMyPermissions } from '@/features/roles/actions';
import { authLoader, unAuthLoader, permissionsLoader } from './loaders';

import type { SideMenuItem, SideMenuRoute } from './types';

export const useSideMenuHandler = () => {
  const [currentRoute, setCurrentRoute] = useState<{ openKeys: string[]; selectedKeys: string[] }>({
    openKeys: [],
    selectedKeys: [],
  });

  const { data: permissions } = useMyPermissions();

  const location = useLocation();
  const navigate = useNavigate();

  const rawRoutes = useMemo(
    () => [
      {
        key: 'access-menu',
        label: 'Access',
        icon: <LockOutlined />,
        children: [
          {
            key: '/dashboard/roles',
            label: 'Roles',
            scope: ['admin.permissions'],
          },
          {
            key: '/dashboard/users/all',
            label: 'Users',
            scope: ['admin.users'],
          },
        ],
      },
      {
        key: '/dashboard/inspection-requests',
        label: 'Inspection Requests',
        icon: <FileTextOutlined />,
      },
      { type: 'divider' } as SideMenuItem,
      {
        key: 'psi-jamaica-menu',
        label: 'Jamaica (PSI)',
        icon: <SendOutlined />,
        scope: ['psi.jamaica'],
        children: [
          {
            key: '/dashboard/psi/japan/jamaica',
            label: 'Japan',
            scope: ['psi.jamaica.japan'],
          },
          {
            key: '/dashboard/psi/singapore/jamaica',
            label: 'Singapore',
            scope: ['psi.jamaica.singapore'],
          },
          {
            key: '/dashboard/psi/uae/jamaica',
            label: 'UAE',
            scope: ['psi.jamaica.uae'],
          },
          {
            key: '/dashboard/psi/uk/jamaica',
            label: 'UK',
            scope: ['psi.jamaica.uk'],
          },
          {
            key: '/dashboard/psi/uk-london/jamaica',
            label: 'UK (London)',
            scope: ['psi.jamaica.uk-london'],
          },
          {
            key: '/dashboard/psi/usa/jamaica',
            label: 'USA',
            scope: ['psi.jamaica.usa'],
          },
        ],
      },
      {
        key: 'rwi-zambia-menu',
        label: 'Zambia (RWI)',
        icon: <SendOutlined />,
        scope: ['rwi.zambia'],
        children: [
          {
            key: '/dashboard/rwi/japan/zambia',
            label: 'Japan',
            scope: ['rwi.zambia.japan'],
          },
          {
            key: '/dashboard/rwi/singapore/zambia',
            label: 'Singapore',
            scope: ['rwi.zambia.singapore'],
          },
          {
            key: '/dashboard/rwi/uae/zambia',
            label: 'UAE',
            scope: ['rwi.zambia.uae'],
          },
          {
            key: '/dashboard/rwi/uk/zambia',
            label: 'UK',
            scope: ['rwi.zambia.uk'],
          },
          {
            key: '/dashboard/rwi/uk-london/zambia',
            label: 'UK (London)',
            scope: ['rwi.zambia.uk-london'],
          },
          {
            key: '/dashboard/rwi/usa/zambia',
            label: 'USA',
            scope: ['rwi.zambia.usa'],
          },
          {
            key: '/dashboard/rwi/zambia/zambia',
            label: 'Zambia',
            scope: ['rwi.zambia.zambia'],
          },
        ],
      },
      {
        key: '/dashboard/nzta',
        label: 'New Zealand (NZTA)',
        icon: <SendOutlined />,
        scope: ['nzta'],
      },
      {
        key: '/dashboard/odometer/japan',
        label: 'Odometer',
        icon: <DashboardOutlined />,
        scope: ['odometer.japan'],
      },
    ],
    []
  );

  const menuItems = useMemo(() => {
    const filterItems = (items: SideMenuItem[]): SideMenuItem[] => {
      return items.reduce((filtered: SideMenuItem[], item: SideMenuItem) => {
        const children = item.children ? filterItems(item.children) : undefined;

        if (item.scope) {
          if (isPermitted(permissions, item.scope)) {
            filtered.push({ ...item, children });
          }
        } else {
          filtered.push({ ...item, children });
        }

        return filtered;
      }, []);
    };

    return filterItems(rawRoutes);
  }, [rawRoutes, permissions]);

  const routeRelations = useMemo(() => {
    const transformMenuItems = (items: typeof rawRoutes, parent = '') => {
      const result: SideMenuRoute[] = [];

      items.forEach((obj) => {
        const { key, children } = obj;
        const path = key;
        const transformedObj = { path, parent };
        result.push(transformedObj);

        if (children && Array.isArray(children)) {
          const childResults = transformMenuItems(children as typeof rawRoutes, path);
          result.push(...childResults);
        }
      });

      return result;
    };

    return transformMenuItems(rawRoutes);
  }, [rawRoutes]);

  const handleChange = (openKeys: string[]) => {
    const state = { ...currentRoute };
    state.openKeys = openKeys;
    setCurrentRoute(state);
  };

  const handleClick = ({ key }: { key: string }) => navigate(key);

  useEffect(() => {
    const matchingRouteRelation = routeRelations.find((obj) => location.pathname.startsWith(obj.path));

    if (matchingRouteRelation) {
      const nextState = {
        openKeys: [matchingRouteRelation.parent],
        selectedKeys: [matchingRouteRelation.path],
      };

      setCurrentRoute(nextState);
    }
  }, [routeRelations, location.pathname]);

  return {
    menuItems,
    currentRoute,

    handleChange,
    handleClick,
  };
};

export const useRoutes = () => {
  const { data: permissions } = useMyPermissions();

  const rawRoutes = [
    {
      path: '/',
      element: <FreeLayout />,
      loader: unAuthLoader,
      children: [
        {
          path: '/',
          lazy: () => import('@/pages/login'),
        },
        {
          path: '/login',
          lazy: () => import('@/pages/login'),
        },
      ],
    },
    {
      path: '/',
      element: <OpenLayout />,
      children: [
        {
          path: '/inspections/create',
          lazy: () => import('@/pages/inspection-requests/create'),
        },
        {
          path: '/inspections/reports/track',
          lazy: () => import('@/pages/verifications/track-by-chassisno'),
        },
        {
          path: '/inspections/reports/track/:destination/:id',
          lazy: () => import('@/pages/verifications/track-by-id'),
        },
        {
          path: '/inspections/reports/verify/:destination/:id',
          lazy: () => import('@/pages/verifications/verify-certificate'),
        },
        {
          path: '/inspections/reports/verify/ncr/:destination/:id',
          lazy: () => import('@/pages/verifications/verify-ncr'),
        },
      ],
    },
    {
      path: '/dashboard',
      element: <MainLayout />,
      loader: authLoader,
      children: [
        // - Roles & Permissions Routes
        {
          path: '/dashboard/roles',
          loader: () => permissionsLoader(permissions, ['admin.permissions.view']),
          lazy: () => import('@/pages/roles/list'),
        },
        {
          path: '/dashboard/roles/create',
          loader: () => permissionsLoader(permissions, ['admin.permissions.create']),
          lazy: () => import('@/pages/roles/create'),
        },
        {
          path: '/dashboard/roles/:id/view',
          loader: () => permissionsLoader(permissions, ['admin.permissions.view']),
          lazy: () => import('@/pages/roles/view'),
        },
        {
          path: '/dashboard/roles/:id/edit',
          loader: () => permissionsLoader(permissions, ['admin.permissions.update']),
          lazy: () => import('@/pages/roles/edit'),
        },

        // - Users Routes
        {
          path: '/dashboard/users/:tab',
          loader: () => permissionsLoader(permissions, ['admin.users.view']),
          lazy: () => import('@/pages/users/list'),
        },
        {
          path: '/dashboard/users/create',
          loader: () => permissionsLoader(permissions, ['admin.users.create']),
          lazy: () => import('@/pages/users/create'),
        },
        {
          path: '/dashboard/users/:localId/view',
          loader: () => permissionsLoader(permissions, ['admin.users.view']),
          lazy: () => import('@/pages/users/view'),
        },
        {
          path: '/dashboard/users/:localId/edit',
          loader: () => permissionsLoader(permissions, ['admin.users.update']),
          lazy: () => import('@/pages/users/edit'),
        },
        {
          path: '/dashboard/users/:localId/reset-password',
          loader: () => permissionsLoader(permissions, ['admin.users.update']),
          lazy: () => import('@/pages/users/reset-password'),
        },

        // - Inspection Requests
        {
          path: '/dashboard/inspection-requests',
          lazy: () => import('@/pages/inspection-requests/list'),
        },

        // - PSI Routes
        {
          path: '/dashboard/psi/:origin/:destination',
          loader: (params: any) => permissionsLoader(permissions, ['psi.:destination.:origin'], params),
          lazy: () => import('@/pages/psi/list'),
        },
        {
          path: '/dashboard/psi/:origin/:destination/:tab',
          loader: (params: any) => permissionsLoader(permissions, ['psi.:destination.:origin.:tab'], params),
          lazy: () => import('@/pages/psi/list'),
        },
        {
          path: '/dashboard/psi/:origin/:destination/create',
          loader: (params: any) => permissionsLoader(permissions, ['psi.:destination.:origin.create'], params),
          lazy: () => import('@/pages/psi/create'),
        },
        {
          path: '/dashboard/psi/:origin/:destination/:id/view',
          loader: (params: any) => permissionsLoader(permissions, ['psi.:destination.:origin.view'], params),
          lazy: () => import('@/pages/psi/view'),
        },
        {
          path: '/dashboard/psi/:origin/:destination/:id/edit',
          loader: (params: any) => permissionsLoader(permissions, ['psi.:destination.:origin.update'], params),
          lazy: () => import('@/pages/psi/edit'),
        },

        // - RWI Routes
        {
          path: '/dashboard/rwi/:origin/:destination',
          loader: (params: any) => permissionsLoader(permissions, ['rwi.:destination.:origin'], params),
          lazy: () => import('@/pages/rwi/list'),
        },
        {
          path: '/dashboard/rwi/:origin/:destination/:tab',
          loader: (params: any) => permissionsLoader(permissions, ['rwi.:destination.:origin.:tab'], params),
          lazy: () => import('@/pages/rwi/list'),
        },
        {
          path: '/dashboard/rwi/:origin/:destination/create',
          loader: (params: any) => permissionsLoader(permissions, ['rwi.:destination.:origin.create'], params),
          lazy: () => import('@/pages/rwi/create'),
        },
        {
          path: '/dashboard/rwi/:origin/:destination/:id/view',
          loader: (params: any) => permissionsLoader(permissions, ['rwi.:destination.:origin.view'], params),
          lazy: () => import('@/pages/rwi/view'),
        },
        {
          path: '/dashboard/rwi/:origin/:destination/:id/edit',
          loader: (params: any) => permissionsLoader(permissions, ['rwi.:destination.:origin.update'], params),
          lazy: () => import('@/pages/rwi/edit'),
        },

        // - Odometer Routes
        {
          path: '/dashboard/odometer/:origin',
          loader: (params: any) => permissionsLoader(permissions, ['odometer.:origin'], params),
          lazy: () => import('@/pages/odometer/list'),
        },
        {
          path: '/dashboard/odometer/:origin/:tab',
          loader: (params: any) => permissionsLoader(permissions, ['odometer.:origin.:tab'], params),
          lazy: () => import('@/pages/odometer/list'),
        },
        {
          path: '/dashboard/odometer/:origin/create',
          loader: (params: any) => permissionsLoader(permissions, ['odometer.:origin.create'], params),
          lazy: () => import('@/pages/odometer/create'),
        },
        {
          path: '/dashboard/odometer/:origin/:id/view',
          loader: (params: any) => permissionsLoader(permissions, ['odometer.:origin.view'], params),
          lazy: () => import('@/pages/odometer/view'),
        },
        {
          path: '/dashboard/odometer/:origin/:id/edit',
          loader: (params: any) => permissionsLoader(permissions, ['odometer.:origin.update'], params),
          lazy: () => import('@/pages/odometer/edit'),
        },

        {
          path: '/dashboard/nzta',
          loader: (params: any) => permissionsLoader(permissions, ['nzta'], params),
          lazy: () => import('@/pages/nzta/list'),
        },
        {
          path: '/dashboard/nzta/:id/view',
          loader: (params: any) => permissionsLoader(permissions, ['nzta.view'], params),
          lazy: () => import('@/pages/nzta/view'),
        },
        {
          path: '/dashboard/nzta/:tab',
          loader: (params: any) => permissionsLoader(permissions, ['nzta.:tab'], params),
          lazy: () => import('@/pages/nzta/list'),
        },
        {
          path: '/dashboard/nzta/:id/edit',
          loader: (params: any) => permissionsLoader(permissions, ['nzta.edit'], params),
          lazy: () => import('@/pages/nzta/edit'),
        },
      ],
    },
    {
      path: '/user',
      element: <FreeLayout />,
      loader: authLoader,
      children: [
        {
          path: '/user/profile',
          lazy: () => import('@/pages/users/profile'),
        },
      ],
    },
  ];

  const routes = createBrowserRouter(rawRoutes);
  return <RouterProvider router={routes} />;
};
