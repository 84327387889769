import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '@/config';
import { RootState } from '@/app/store';
import authService from '@/features/auth/service';
import { bindToken, buildQueryParams } from '@/services/utils';
import { getPermissionsRes, getRolesRes, getRolesByIdRes, updateRoleReq, getMyPermissionsRes } from './service-mapping';

import type {
  GetPermissionRes,
  GetPermissionApiRes,
  GetRoleReq,
  GetRoleRes,
  GetRoleApiRes,
  GetRoleByIdReq,
  GetRoleByIdRes,
  GetRoleByIdApiRes,
  UpdateRoleReq,
  DeleteRoleReq,
  GetMyPermissionRes,
  GetMyPermissionsApiRes,
} from './types';

const service = createApi({
  reducerPath: 'role-permissions-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders: (headers, { endpoint, getState }) => {
      bindToken(['getPermissions', 'getAll', 'getById', 'update', 'delete', 'getMyPermissions'], endpoint, headers, getState() as RootState);
    },
  }),
  tagTypes: ['getMyPermissions', 'getAll'],
  endpoints: (builder) => ({
    getPermissions: builder.query<GetPermissionRes, void>({
      query: () => ({
        url: '/admin/permission/',
      }),
      transformResponse: (res: GetPermissionApiRes) => {
        return getPermissionsRes(res);
      },
    }),
    getAll: builder.query<GetRoleRes, GetRoleReq>({
      query: ({ pagination }) => {
        const queryParams = buildQueryParams({ pagination });
        return {
          url: `/admin/permission/role?${queryParams}`,
        };
      },
      transformResponse: (res: GetRoleApiRes) => {
        return getRolesRes(res);
      },
      providesTags: ['getAll'],
      keepUnusedDataFor: 0,
    }),
    getById: builder.query<GetRoleByIdRes, GetRoleByIdReq>({
      query: ({ params }) => {
        return {
          url: `/admin/permission/role/${params.id}`,
        };
      },
      transformResponse: (res: GetRoleByIdApiRes) => {
        return getRolesByIdRes(res);
      },
      keepUnusedDataFor: 0,
    }),
    update: builder.mutation<void, UpdateRoleReq>({
      query: (args) => {
        const payload = updateRoleReq(args);

        return {
          url: '/admin/permission/role',
          method: 'POST',
          body: payload,
        };
      },
    }),
    delete: builder.mutation<void, DeleteRoleReq>({
      query: ({ params }) => {
        return {
          url: `/admin/permission/role/${params.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['getAll'],
    }),
    getMyPermissions: builder.query<GetMyPermissionRes, void>({
      query: () => ({
        url: '/permissions',
      }),
      transformResponse: (res: GetMyPermissionsApiRes) => {
        return getMyPermissionsRes(res);
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(authService.endpoints.logout.initiate());
        }
      },
      providesTags: ['getMyPermissions'],
      keepUnusedDataFor: 1,
    }),
  }),
});

const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,
  util: service.util,

  getPermissions: service.useGetPermissionsQuery,
  getMyPermissions: service.useGetMyPermissionsQuery,
  getAll: service.useGetAllQuery,
  getById: service.useGetByIdQuery,
  update: service.useUpdateMutation,
  delete: service.useDeleteMutation,
};

export default serviceConfig;
