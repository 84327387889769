import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/app/store';

type Theme = 'dark' | 'light';
interface User {
  displayName?: string;
  initials?: string;
  email?: string;
}
interface Auth {
  token?: string;
  expirationTime?: number;
}
interface State {
  user?: User | null;
  auth?: Auth | null;
  theme?: Theme;
}

const initialState: State = {
  user: null,
  auth: null,
  theme: (localStorage.getItem('theme') as Theme) || 'light',
};

const sessionSlice = createSlice({
  name: 'sessionSlice',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<State>) => {
      let initials = null;
      if (action.payload.user) {
        initials = action.payload.user.displayName
          ?.split(' ')
          .map((n) => n[0].charAt(0))
          .join('');
      }
      return Object.assign(state, {
        user: {
          ...action.payload.user,
          initials,
        },
        auth: {
          ...action.payload.auth,
        },
      });
    },
    setUser: (state, action: PayloadAction<User>) => {
      return Object.assign(state, {
        user: action.payload,
      });
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      return Object.assign(state, {
        authToken: action.payload,
      });
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      localStorage.setItem('theme', action.payload);
      return Object.assign(state, {
        theme: action.payload,
      });
    },
  },
});

export const { setSession, setTheme } = sessionSlice.actions;

export const selectUser = (state: RootState) => state.sessionSlice.user;

export const selectTheme = (state: RootState) => state.sessionSlice.theme;

export const selectAuth = (state: RootState) => state.sessionSlice.auth;

export default sessionSlice;
