import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '@/config';
import { RootState } from '@/app/store';
import { bindToken } from '@/services/utils';
import { getCertificatesMetaRes, getFacilitiesRes, getRolesRes } from './mappings';

import type { GetCertificatesMetaRes, GetCertificatesMetaApiRes, GetFacilitiesRes, GetFacilitiesApiRes, GetRolesRes, GetRolesApiRes } from './types';

const service = createApi({
  reducerPath: 'app-config-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders: (headers, { endpoint, getState }) => {
      bindToken(['getRoles'], endpoint, headers, getState() as RootState);
    },
  }),
  endpoints: (builder) => ({
    getCertificatesMeta: builder.query<GetCertificatesMetaRes, void>({
      query: () => ({
        url: '/config/certificate',
      }),
      transformResponse: (res: GetCertificatesMetaApiRes) => {
        return getCertificatesMetaRes(res);
      },
    }),
    getFacilities: builder.query<GetFacilitiesRes, void>({
      query: () => ({
        url: '/config/branch',
      }),
      transformResponse: (res: GetFacilitiesApiRes) => {
        return getFacilitiesRes(res);
      },
    }),
    getRoles: builder.query<GetRolesRes, void>({
      query: () => ({
        url: '/admin/permission/role/list',
      }),
      transformResponse: (res: GetRolesApiRes) => {
        return getRolesRes(res);
      },
    }),
  }),
});

export default service;
