import { redirect } from 'react-router-dom';
import { lsToken } from '@/services/utils';
import { isPermitted, getRuntimeScope } from '@/helpers/permissionsHelper';

import type { LoaderProps } from './types';

export const isAuthorized = () => {
  return !!lsToken();
};

export const authLoader = () => {
  if (!isAuthorized()) {
    return redirect('/login');
  }

  return true;
};

export const unAuthLoader = () => {
  if (isAuthorized()) {
    return redirect('/dashboard');
  }

  return true;
};

export const permissionsLoader = (permissions: string[], scope: string[], request?: LoaderProps) => {
  let runtimeScope: string[] = [];
  if (request) {
    runtimeScope = getRuntimeScope(scope, request.params);
  }

  if (!isPermitted(permissions, [...scope, ...runtimeScope])) {
    return redirect('/dashboard');
  }

  return true;
};
