import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Col, Layout, Row, theme, Typography, Space, Divider } from 'antd';
import styled from 'styled-components';
import logo from '@/assets/atj-logo.png';
import { Container } from '@/components';

import type { StyledProps } from '@/types';

const OpenLayout: FC = () => {
  const { colorBgContainer } = theme.useToken().token;

  return (
    <Layout>
      <Header $colorBg={colorBgContainer}>
        <Row justify='space-around'>
          <LogoWrapper span={12}>
            <a href='http://autoterminalpsi.com'>
              <Logo src={logo} alt='ATJ' />
            </a>
          </LogoWrapper>
        </Row>
      </Header>
      <Body>
        <Outlet />
      </Body>
      <Layout.Footer>
        <Divider />
        <Container>
          <Row justify='space-between'>
            <FooterCol xs={24} sm={12}>
              <Space direction='vertical' size='small'>
                <Typography.Text strong>ATJ Inspections Lines</Typography.Text>
                <Typography.Text>East (Main Office)</Typography.Text>
                <Typography.Text>1-17-69 Shiohama Kisarazu-shi Chiba</Typography.Text>
                <Typography.Text>292-0838 Japan</Typography.Text>
                <br />
                <Typography.Text>Tel: +81-438-30-7488</Typography.Text>
                <Typography.Text>Fax: +81-438-30-7489</Typography.Text>
              </Space>
            </FooterCol>
            <FooterCol xs={24} sm={12}>
              ©2024 inspectionsapp.jp
            </FooterCol>
          </Row>
        </Container>
      </Layout.Footer>
    </Layout>
  );
};

const Header = styled(Layout.Header)<StyledProps>`
  position: sticky !important;
  top: 0;
  z-index: 1;
  padding: 0;
  background: ${(props) => props.$colorBg} !important;
`;
const LogoWrapper = styled(Col)`
  text-align: center;
`;

const Logo = styled.img`
  margin-top: 10px;
  height: 60px;
`;

const Body = styled(Layout.Content)`
  padding-bottom: 24px;
  min-height: calc(100vh - (335px + 60px));
`;

const FooterCol = styled(Col)`
  &:last-child {
    text-align: right;
  }

  @media (max-width: 576px) {
    text-align: center;

    &:last-child {
      margin-top: 20px;
      text-align: center;
    }
  }
`;

export default OpenLayout;
