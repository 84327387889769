import { configureStore } from '@reduxjs/toolkit';

import sessionSlice from '@/slices/session';

import appConfigService from '../services/app-config';

import authService from '@/features/auth/service';
import roleService from '@/features/roles/service';
import userService from '@/features/user/service';
import inspectionRequestService from '@/features/inspection-request/service';
import verificationService from '@/features/verifications/service';
import psiService from '@/features/psi/service';
import rwiService from '@/features/rwi/service';
import odometerService from '@/features/odometer/service';
import nztaService from '@/features/nzta/service';

export const store = configureStore({
  reducer: {
    [sessionSlice.name]: sessionSlice.reducer,

    [appConfigService.key]: appConfigService.reducer,

    [authService.key]: authService.reducer,
    [roleService.key]: roleService.reducer,
    [userService.key]: userService.reducer,
    [inspectionRequestService.key]: inspectionRequestService.reducer,
    [verificationService.key]: verificationService.reducer,
    [psiService.key]: psiService.reducer,
    [rwiService.key]: rwiService.reducer,
    [odometerService.key]: odometerService.reducer,
    [nztaService.key]: nztaService.reducer,
  },
  middleware: (baseMiddleware) =>
    baseMiddleware({ serializableCheck: false })
      .concat(appConfigService.middleware)

      .concat(authService.middleware)
      .concat(roleService.middleware)
      .concat(userService.middleware)
      .concat(inspectionRequestService.middleware)
      .concat(verificationService.middleware)
      .concat(psiService.middleware)
      .concat(rwiService.middleware)
      .concat(odometerService.middleware)
      .concat(nztaService.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
