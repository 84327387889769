import { FC, ReactNode, useMemo } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, MenuProps, Row, Space, Typography } from 'antd';
import styled from 'styled-components';
import { Container } from '@/components';
import { useValidatePermission } from '@/features/roles/actions';
import { useDashboardHandler } from './handler';

import type { Permissions } from '@/features/roles/types';

type HeaderAction = {
  type: 'button' | 'dropdown';
  label: string;
  icon?: ReactNode;
  loading?: boolean;
  scope?: Permissions[];
  onClick: (data?: any) => void;
};

type ConditionalProps = (HeaderAction & { type: 'button'; options?: never }) | (HeaderAction & { type: 'dropdown'; options: MenuProps['items'] });

interface DashboardProps {
  tabName?: string;
  title: string;
  description: string;
  actions?: Array<ConditionalProps>;
  children: ReactNode;
}

const Dashboard: FC<DashboardProps> = ({ tabName, title, description, actions = [], children }) => {
  const { handleBack } = useDashboardHandler({ tabName });
  const { isPermitted } = useValidatePermission();

  const actionItems = useMemo(
    () =>
      actions.map((props, key) => {
        if (props.type === 'button' && (!props.scope || isPermitted(props.scope || []))) {
          return (
            <Button key={props.label} icon={props.icon} loading={props.loading} onClick={props.onClick}>
              {props.label}
            </Button>
          );
        }

        if (props.type === 'dropdown') {
          const menu = {
            onClick: props.onClick,
            items: props.options,
          };

          return (
            <Dropdown key={key} menu={menu}>
              <Button icon={props.icon} loading={props.loading}>
                {props.label}
              </Button>
            </Dropdown>
          );
        }

        return null;
      }),
    [actions, isPermitted]
  );

  return (
    <Container>
      <BackButton shape='circle' type='ghost' icon={<ArrowLeftOutlined />} onClick={handleBack} />
      <HeaderWrapper>
        <Row>
          <Col span={18}>
            <Title level={4}>{title}</Title>
            <Typography.Text type='secondary'>{description}</Typography.Text>
          </Col>
          <Col span={6}>
            <ActionsWrapper>{actionItems}</ActionsWrapper>
          </Col>
        </Row>
      </HeaderWrapper>
      {children}
    </Container>
  );
};

const HeaderWrapper = styled.div`
  margin-bottom: 28px;
`;

const Title = styled(Typography.Title)`
  margin-top: 10px;
  margin-bottom: 5px !important;
`;

const BackButton = styled(Button)`
  position: absolute;
  left: -35px;
  top: 3px;
`;

const ActionsWrapper = styled(Space)`
  margin-top: 18px;
  float: right;
`;

export default Dashboard;
