import { FC } from 'react';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Upload } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

import type { CFormItemProps } from './types';

const FormItem: FC<CFormItemProps> = ({ size, type, label, name, placeholder, rules, prefix, suffix, allowClear, disabled = false, options }) => {
  const customProps: any = {};
  const inputProps: any = {
    allowClear,
    disabled,
  };
  if (type === 'checkbox') {
    customProps.valuePropName = 'checked';
  }
  if (type === 'date') {
    inputProps.picker = options?.type || 'date';
    if (options?.noFuture) {
      inputProps.disabledDate = (current: any) => current && current > dayjs().endOf('day');
    }
  }
  if (type === 'upload') {
    customProps.initialValue = [];
    customProps.valuePropName = 'fileList';
    customProps.getValueFromEvent = (e: any) => (e && e.fileList) || [];
    inputProps.customRequest = (file: any) => options?.xhr(name, file);
  }

  if (prefix) {
    inputProps.addonAfter = (
      <Form.Item name={prefix.name} noStyle>
        <Select style={{ width: prefix.width || 70 }} options={prefix.options} />
      </Form.Item>
    );
  }
  if (suffix) {
    inputProps.addonAfter = (
      <Form.Item name={suffix.name} noStyle>
        <Select style={{ width: suffix.width || 70 }} options={suffix.options} />
      </Form.Item>
    );
  }

  const content =
    type === 'list-item' ? (
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <Row key={key} align='top'>
                <Col span={23}>
                  <Row>
                    {(options || []).map((option) => (
                      <FormItem {...option} key={option.name as string} name={[name, option.name as string]} />
                    ))}
                  </Row>
                </Col>
                <Col span={1} style={{ textAlign: 'center' }}>
                  <MinusCircleOutlined style={{ paddingTop: 40 }} onClick={() => remove(name)} />
                </Col>
              </Row>
            ))}
            <Row justify='center'>
              <Col span={10}>
                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Field
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    ) : (
      <>
        <Form.Item name={name} rules={rules} label={label} {...customProps}>
          {type === 'select' ? (
            <Select options={options} {...inputProps} />
          ) : type === 'date' ? (
            <Date {...inputProps} />
          ) : type === 'date-range' ? (
            <DateRange {...inputProps} />
          ) : type === 'radio' ? (
            <Radio.Group>
              {options?.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          ) : type === 'upload' ? (
            <Upload {...inputProps} maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          ) : type === 'checkbox' ? (
            <Checkbox />
          ) : type === 'textarea' ? (
            <Input.TextArea {...inputProps} />
          ) : type === 'password' ? (
            <Input.Password placeholder={placeholder} {...inputProps} />
          ) : (
            <Input type={type} placeholder={placeholder} {...inputProps} />
          )}
        </Form.Item>
        {type === 'upload' && <Form.Item hidden name={`${name}_value`} />}
      </>
    );

  return <>{size ? <WrapperCol {...size}>{content}</WrapperCol> : <>{content}</>}</>;
};

const WrapperCol = styled(Col)`
  padding: 0 5px;
`;

const Date = styled(DatePicker)`
  width: 100%;
`;

const DateRange = styled(Date.RangePicker)`
  width: 100%;
`;

export default FormItem;
