import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '@/app/hooks';
import { isPermitted } from '@/helpers/permissionsHelper';
import { isAuthorized } from '@/routes/loaders';
import service from '../service';

import type { GetByIdProps, UpdateActionProps, UpdateProps } from './types';
import type { Permissions } from '../types';

export const useGetRoles = () => {
  const [page, setPage] = useState<number>(1);

  const payload = {
    params: null,
    query: null,
    pagination: { page, limit: 25 },
    body: null,
  };

  const { data: roles, isLoading, isFetching } = service.getAll(payload);

  const data = roles?.results || [];
  const pagination = Number(roles?.meta?.total) > payload.pagination.limit ? roles?.meta : false;

  const onChangePage = useCallback(
    (nextPage: number) => {
      if (nextPage) {
        setPage(nextPage);
      }
    },
    [setPage]
  );

  return {
    isLoading,
    isFetching,
    data,
    pagination,

    onChangePage,
  };
};

export const useGetRoleById = ({ id }: GetByIdProps) => {
  const payload = {
    params: { id },
    query: null,
    body: null,
  };
  const { data: role, isLoading, isFetching } = service.getById(payload);
  const data = role?.result;

  return {
    isLoading,
    isFetching,
    data,
  };
};

export const useUpdateRole = ({ id }: UpdateProps) => {
  const navigate = useNavigate();
  const { notification } = useAppContext();

  const [updateAction, { isLoading, isSuccess, isError, error }] = service.update();

  const onUpdate = useCallback(
    (body: UpdateActionProps) => {
      const payload = {
        params: null,
        query: null,
        body: { ...body, id },
      };

      updateAction(payload);
    },
    [id, updateAction]
  );

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Success',
        description: `Role ${id ? 'updated' : 'created'} successfully.`,
      });
      navigate(`/dashboard/roles`);
    }

    if (isError) {
      // @ts-ignore
      const description = error?.data?.message || `Something went wrong while ${id ? 'updating' : 'creating'} the role. Please try again.`;
      notification.error({
        message: 'Error',
        description,
      });
    }
  }, [id, isSuccess, isError, error, notification, navigate]);

  return {
    isUpdating: isLoading,
    onUpdate,
  };
};

export const useDeleteRole = () => {
  const { notification } = useAppContext();

  const [deleteAction, { isLoading, isSuccess, isError, error }] = service.delete();

  const onDeleteRole = useCallback(
    (id: number) => {
      const payload = {
        params: { id },
        query: null,
        body: null,
      };

      deleteAction(payload);
    },
    [deleteAction]
  );

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Success',
        description: 'Role deleted successfully.',
      });
    }

    if (isError) {
      // @ts-ignore
      const description = error?.data?.message || 'Something went wrong while deleting the role. Please try again.';
      notification.error({
        message: 'Error',
        description,
      });
    }
  }, [isSuccess, isError, error, notification]);

  return {
    isDeleting: isLoading,
    onDeleteRole,
  };
};

export const usePermissions = () => {
  const { data = [], isLoading, isFetching } = service.getPermissions();

  return {
    isLoading,
    isFetching,
    data,
  };
};

export const useMyPermissions = () => {
  const isAuth = isAuthorized();
  const { data = [], isLoading, isFetching } = service.getMyPermissions(undefined, { skip: !isAuth });

  return {
    isLoading,
    isFetching,
    data,
  };
};

export const useValidatePermission = () => {
  const { data: permissions } = useMyPermissions();

  const validateScope = (scope: Permissions[]) => {
    return isPermitted(permissions, scope);
  };

  return {
    isPermitted: validateScope,
  };
};
