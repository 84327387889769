import { getDateObj, getDateStr, getDateTimeStr } from '@/services/utils';

import type {
  CreateReq,
  GetAllReq,
  GetAllRes,
  GetAllApiRes,
  GetByIdReq,
  GetByIdRes,
  GetByIdApiRes,
  UpdateReq,
  UpdateIssuedDateReq,
  GenerateCertificatesReq,
  GenerateCertificatesRes,
  GenerateCertificatesApiRes,
  GetCertificatesReq,
  GetCertificatesRes,
  GetCertificatesApiRes,
  ExportFileReq,
  ImportFileReq,
  ImportFileRes,
  ImportFileApiRes,
  ImportConfirmReq,
} from './types';

export const createReq = ({ body, scope }: CreateReq) => {
  if (scope === 'jamaica') {
    const yearManufacture = getDateObj(body.yearManufacture);
    const modelYear = getDateObj(body.modelYear);
    const firstRegistration = getDateObj(body.firstRegistration);

    return {
      cor_no: body.corNo,
      country_supply: body.countrySupply,
      country_export: body.countryExport,
      inspection_branch_id: Number(body.inspectionBranchId),
      inspection_date: getDateStr(body.inspectionDate),
      application_date: getDateStr(body.applicationDate),
      date_of_issue: getDateStr(body.issuedDate),

      category: body.category,
      title: body.condition,
      make: body.make,
      model: body.model,
      chassis_no: body.chassisNo,
      country_manufacture: body.countryManufacture,
      year_of_manufacture: Number(yearManufacture.year),
      model_year: Number(modelYear.year),
      first_registration_year: Number(firstRegistration.year),
      first_registration_month: Number(firstRegistration.month),
      original_color: body.originalColor,
      seating_capacity: Number(body.seatingCapacity),
      engine_no: body.engineNo,
      engine_capacity: Number(body.engineCapacity),
      engine_capacity_unit: body.engineCapacityUnit,
      type_of_fuel: body.fuelType,
      odometer_reading: Number(body.odometerReading),
      odometer_reading_unit: body.odometerReadingUnit,
      laden_unladen_weight: Number(body.ladenUnladenWeight),
      laden_unladen_weight_unit: body.ladenUnladenWeightUnit,

      modification: body.modification,
      structure_water_fire_damage: body.structureWaterFireDamage,
      percentage_tire_wear: body.percentageTireWear,
      microbial_contamination: body.microbialContamination,
      radiation_contamination: body.radiationContamination,
      remarks: body.remarks,
    };
  }
};

export const getAllReq = ({ query, scope }: GetAllReq) => {
  const queryParams: any = {};
  if (scope === 'jamaica') {
    const inspectionDate = query.inspectionDate;
    const issuedDate = query.issuedDate;

    const issuedDateObj = issuedDate ? getDateObj(issuedDate) : undefined;
    const df = inspectionDate ? getDateStr(inspectionDate?.[0]) : undefined;
    const dt = inspectionDate ? getDateStr(inspectionDate?.[1]) : undefined;
    const ym = issuedDateObj ? `${issuedDateObj.year}${issuedDateObj.month}` : undefined;

    queryParams.crn = query.corNo;
    queryParams.chn = query.chassisNo;
    queryParams.ctg = query.category;
    queryParams.tit = query.condition;
    queryParams.df = df;
    queryParams.dt = dt;
    queryParams.ym = ym;
  }

  return queryParams;
};
export const getAllRes = (response: GetAllApiRes, args: GetAllReq) => {
  const { destination } = args.params;

  const data: Partial<GetAllRes> = {
    meta: {
      current: response.data.page,
      pageSize: response.data.limit,
      total: response.data.total_rows,
    },
    scope: destination,
  };

  if (data.scope === 'jamaica') {
    data.results = response.data.rows.map((row) => ({
      uuid: row.uuid,
      corNo: row.cor_no,
      issuedDate: row.date_of_issue,
      inspectionDate: row.inspection_date,
      make: row.make,
      model: row.model,
      chassisNo: row.chassis_no,
      certificateIssued: row.certificate_issued,
    }));
  }

  return data as GetAllRes;
};

export const getByIdRes = (response: GetByIdApiRes, args: GetByIdReq) => {
  const { destination } = args.params;

  const data: Partial<GetByIdRes> = {
    scope: destination,
  };

  if (data.scope === 'jamaica') {
    data.result = {
      uuid: response.data.uuid,
      corNo: response.data.cor_no,
      countrySupply: response.data.country_supply,
      countryExport: response.data.country_export,
      inspectionBranchId: response.data.inspection_branch_id,
      inspectionBranchName: response.data.inspection_branch_name,
      inspectionDate: response.data.inspection_date,
      applicationDate: response.data.application_date,
      issuedDate: response.data.date_of_issue,

      category: response.data.category,
      condition: response.data.title,
      make: response.data.make,
      model: response.data.model,
      chassisNo: response.data.chassis_no,
      countryManufacture: response.data.country_manufacture,
      yearManufacture: response.data.year_of_manufacture.toString(),
      modelYear: response.data.model_year.toString(),
      firstRegistration: `${response.data.first_registration_year}-${response.data.first_registration_month.toString().padStart(2, '0')}`,
      originalColor: response.data.original_color,
      seatingCapacity: response.data.seating_capacity,
      engineNo: response.data.engine_no,
      engineCapacity: response.data.engine_capacity,
      engineCapacityUnit: response.data.engine_capacity_unit,
      fuelType: response.data.type_of_fuel,
      odometerReading: response.data.odometer_reading,
      odometerReadingUnit: response.data.odometer_reading_unit,
      ladenUnladenWeight: response.data.laden_unladen_weight,
      ladenUnladenWeightUnit: response.data.laden_unladen_weight_unit,

      modification: response.data.modification,
      structureWaterFireDamage: response.data.structure_water_fire_damage,
      percentageTireWear: response.data.percentage_tire_wear,
      microbialContamination: response.data.microbial_contamination,
      radiationContamination: response.data.radiation_contamination,
      remarks: response.data.remarks,

      certificateIssued: response.data.certificate_issued,
    };
  }

  return data as GetByIdRes;
};

export const updateReq = (args: UpdateReq) => createReq(args);

export const updateIssuedDateReq = ({ scope, body }: UpdateIssuedDateReq) => {
  if (scope === 'jamaica') {
    return {
      date_of_issue: getDateStr(body.issuedDate),
    };
  }
};

export const generateCertificatesRes = (response: GenerateCertificatesApiRes, args: GenerateCertificatesReq) => {
  const { destination } = args.params;

  const data: Partial<GenerateCertificatesRes> = {
    scope: destination,
  };

  if (data.scope === 'jamaica') {
    data.result = {
      url: response.data.url,
    };
  }

  return data as GenerateCertificatesRes;
};

export const getCertificates = (response: GetCertificatesApiRes, args: GetCertificatesReq) => {
  const { destination } = args.params;

  const data: Partial<GetCertificatesRes> = {
    meta: {
      current: 1,
      pageSize: 0,
      total: 0,
    },
    scope: destination,
  };

  if (data.scope === 'jamaica') {
    data.results = response.data.rows.map((row) => ({
      uuid: row.uuid,
      fileName: row.file_name,
      certificateType: row.certificate_type,
      createdAt: getDateTimeStr(row.created_at),
      key: row.key,
    }));
  }

  return data as GetCertificatesRes;
};

export const exportFileReq = ({ body, scope }: ExportFileReq) => {
  const bodyParams: any = {};
  if (scope === 'jamaica') {
    const inspectionDate = body.inspectionDate;
    const issuedDate = body.issuedDate;

    const issuedDateObj = issuedDate ? getDateObj(issuedDate) : undefined;
    const df = inspectionDate ? getDateStr(inspectionDate?.[0]) : undefined;
    const dt = inspectionDate ? getDateStr(inspectionDate?.[1]) : undefined;
    const ym = issuedDateObj ? `${issuedDateObj.year}${issuedDateObj.month}` : undefined;

    bodyParams.crn = body.corNo;
    bodyParams.chn = body.chassisNo;
    bodyParams.df = df;
    bodyParams.dt = dt;
    bodyParams.ym = ym;
  }

  return bodyParams;
};

export const importFileRes = (response: ImportFileApiRes, args: ImportFileReq) => {
  const { destination } = args.params;

  const data: Partial<ImportFileRes> = {
    scope: destination,
  };

  if (data.scope === 'jamaica') {
    data.result = {
      fileId: response.data.file_id,
      importType: response.data.import_type,
      uniqueIdentifierColumn: response.data.unique_identifier_column,
      requiredColumns: response.data.required_columns,
      columnMapping: response.data.column_mapping,
      country: response.data.country,
      dataErrors: response.data.data_errors,
      duplicates: response.data.duplicates,
      duplicateCheck: response.data.duplicate_check,
      sampleData: response.data.sample_data,
    };
  }

  return data as ImportFileRes;
};

export const importConfirmReq = ({ body, scope }: ImportConfirmReq) => {
  if (scope === 'jamaica') {
    return {
      file_id: body.fileId,
      import_type: body.importType,
      unique_identifier_column: body.uniqueIdentifierColumn,
      required_columns: body.requiredColumns,
      column_mapping: body.columnMapping,
      sample_data: body.sampleData,
      data_errors: body.dataErrors,
      duplicates: body.duplicates,
      duplicate_check: body.duplicateCheck,
      country: body.country,
    };
  }
};
