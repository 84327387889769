import { getDateStr, getDateTimeStr } from '@/services/utils';

import type {
  CreateReq,
  GetAllReq,
  GetAllRes,
  GetAllApiRes,
  GetByIdReq,
  GetByIdRes,
  GetByIdApiRes,
  UpdateReq,
  GenerateCertificatesReq,
  GenerateCertificatesRes,
  GenerateCertificatesApiRes,
  GetCertificatesReq,
  GetCertificatesRes,
  GetCertificatesApiRes,
  ExportFileReq,
  ImportFileReq,
  ImportFileRes,
  ImportFileApiRes,
  ImportConfirmReq,
} from './types';

export const createReq = ({ body }: CreateReq) => {
  return {
    cor_no: body.corNo,
    registration_no: body.registrationNo,
    inspection_date: getDateStr(body.inspectionDate),

    make: body.make,
    model: body.model,
    chassis_no: body.chassisNo,
    odometer_reading: Number(body.odometerReading),
    odometer_reading_unit: body.odometerReadingUnit,

    visual_appraisal_check: body.visualAppraisalCheck,
    documentation_cross_check: body.documentationCrossCheck,
    nak_database_cross_check: body.nakDatabaseCrossCheck,
    pre_judgment: body.preJudgment,
    finale_judgment: body.finaleJudgment,

    remarks: body.remarks,
  };
};

export const getAllReq = ({ query, scope }: GetAllReq) => {
  const queryParams: any = {};
  const inspectionDate = query.inspectionDate;

  const df = inspectionDate ? getDateStr(inspectionDate?.[0]) : undefined;
  const dt = inspectionDate ? getDateStr(inspectionDate?.[1]) : undefined;

  queryParams.crn = query.corNo;
  queryParams.chn = query.chassisNo;
  queryParams.df = df;
  queryParams.dt = dt;
  queryParams.br = query.inspectionBranchId;

  return queryParams;
};
export const getAllRes = (response: GetAllApiRes, args: GetAllReq): GetAllRes => {
  return {
    meta: {
      current: response.data.page,
      pageSize: response.data.limit,
      total: response.data.total_rows,
    },
    results: response.data.rows.map((row) => ({
      uuid: row.uuid,
      corNo: row.cor_no,
      inspectionDate: row.inspection_date,
      make: row.make,
      model: row.model,
      chassisNo: row.chassis_no,
      certificateIssued: row.certificate_issued,
    })),
  };
};

export const getByIdRes = (response: GetByIdApiRes, args: GetByIdReq): GetByIdRes => {
  return {
    result: {
      uuid: response.data.uuid,
      corNo: response.data.cor_no,
      registrationNo: response.data.registration_no,
      inspectionDate: response.data.inspection_date,

      make: response.data.make,
      model: response.data.model,
      chassisNo: response.data.chassis_no,
      odometerReading: response.data.odometer_reading,
      odometerReadingUnit: response.data.odometer_reading_unit,

      visualAppraisalCheck: response.data.visual_appraisal_check,
      documentationCrossCheck: response.data.documentation_cross_check,
      nakDatabaseCrossCheck: response.data.nak_database_cross_check,
      preJudgment: response.data.pre_judgment,
      finaleJudgment: response.data.finale_judgment,

      remarks: response.data.remarks,

      certificateIssued: response.data.certificate_issued,
    },
  };
};

export const updateReq = (args: UpdateReq) => createReq(args);

export const generateCertificatesRes = (response: GenerateCertificatesApiRes, args: GenerateCertificatesReq): GenerateCertificatesRes => {
  return {
    result: {
      url: response.data.url,
    },
  };
};

export const getCertificates = (response: GetCertificatesApiRes, args: GetCertificatesReq): GetCertificatesRes => {
  return {
    meta: {
      current: 1,
      pageSize: 0,
      total: 0,
    },
    results: response.data.rows.map((row) => ({
      uuid: row.uuid,
      fileName: row.file_name,
      certificateType: row.certificate_type,
      createdAt: getDateTimeStr(row.created_at),
      key: row.key,
    })),
  };
};

export const exportFileReq = ({ body, scope }: ExportFileReq) => {
  const inspectionDate = body.inspectionDate;

  const df = inspectionDate ? getDateStr(inspectionDate?.[0]) : undefined;
  const dt = inspectionDate ? getDateStr(inspectionDate?.[1]) : undefined;

  return {
    crn: body.corNo,
    chn: body.chassisNo,
    df,
    dt,
  };
};

export const importFileRes = (response: ImportFileApiRes, args: ImportFileReq): ImportFileRes => {
  return {
    result: {
      fileId: response.data.file_id,
      importType: response.data.import_type,
      uniqueIdentifierColumn: response.data.unique_identifier_column,
      requiredColumns: response.data.required_columns,
      columnMapping: response.data.column_mapping,
      country: response.data.country,
      dataErrors: response.data.data_errors,
      duplicates: response.data.duplicates,
      duplicateCheck: response.data.duplicate_check,
      sampleData: response.data.sample_data,
    },
  };
};

export const importConfirmReq = ({ body, scope }: ImportConfirmReq) => {
  return {
    file_id: body.fileId,
    import_type: body.importType,
    unique_identifier_column: body.uniqueIdentifierColumn,
    required_columns: body.requiredColumns,
    column_mapping: body.columnMapping,
    sample_data: body.sampleData,
    data_errors: body.dataErrors,
    duplicates: body.duplicates,
    duplicate_check: body.duplicateCheck,
    country: body.country,
  };
};
