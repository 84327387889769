import type {
  RegionProps,
  BranchProps,
  GetCertificatesMetaRes,
  GetCertificatesMetaApiRes,
  GetFacilitiesRes,
  GetFacilitiesApiRes,
  GetRolesRes,
  GetRolesApiRes,
} from './types';

export const getCertificatesMetaRes = (response: GetCertificatesMetaApiRes): GetCertificatesMetaRes => {
  const { categories, titles, fuel_engine_map } = response.data;

  const fuelTypes = [
    { value: 'CNG', label: 'CNG' },
    { value: 'Diesel', label: 'Diesel' },
    { value: 'Electric', label: 'Electric' },
    { value: 'Gasoline', label: 'Gasoline' },
    { value: 'Gasoline/Electric', label: 'Gasoline/Electric' },
    { value: 'Gasoline/LPG', label: 'Gasoline/LPG' },
    { value: 'LPG', label: 'LPG' },
    { value: 'N/A', label: 'N/A' },
    { value: 'Other', label: 'Other' },
  ];

  const categoriesMap = categories.map((category) => ({ value: category, label: category }));
  const conditionsMap = titles.map((titles) => ({ value: titles, label: titles }));

  return {
    categories: categoriesMap,
    conditions: conditionsMap,
    fuelTypes,
    fuelTypeEngineCap: fuel_engine_map,
  };
};

export const getFacilitiesRes = (response: GetFacilitiesApiRes): GetFacilitiesRes => {
  const regions: Array<RegionProps> = [];
  const branches: Array<BranchProps> = [];

  const countries = response.data;

  countries.forEach((country) => {
    country.cities.forEach((city) => {
      regions.push({
        value: city.id,
        label: `${country.country_name} - ${city.name}`,
      });

      city.facilities.forEach((facility) => {
        branches.push({
          value: facility.id,
          region: city.id,
          label: facility.branch_name,
        });
      });
    });
  });

  return {
    regions,
    branches,
  };
};

export const getRolesRes = (response: GetRolesApiRes): GetRolesRes => {
  return response.data.map((item) => ({
    value: item.id,
    label: item.role,
  }));
};
