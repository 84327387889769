import { FC, useMemo } from 'react';
import { Row } from 'antd';
import FormItem from './FormItem';

import type { FormSketchProps } from './types';

const FormSketch: FC<FormSketchProps> = ({ items }) => {
  const formItems = useMemo(() => {
    return items.map(({ type, name, label, rules, options, placeholder, disabled, allowClear, size, prefix, suffix }) => {
      const mainProps = {
        type,
        name,
        label,
        rules,
        placeholder,
        disabled,
        allowClear,
        size,
        prefix,
        suffix,
      };
      const optionalProps: any = {};

      if (['select', 'radio', 'date', 'list-item'].includes(type)) {
        optionalProps.options = options;
      }

      return <FormItem key={name} {...mainProps} {...optionalProps} />;
    });
  }, [items]);

  return <Row> {formItems} </Row>;
};

export default FormSketch;
