import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout, theme } from 'antd';
import styled from 'styled-components';
import type { StyledProps } from '@/types';
import logo from '@/assets/atj-logo.png';
import Header from './Header';
import SideMenu from './SideMenu';

const MainLayout: FC = () => {
  const { colorBgContainer } = theme.useToken().token;

  const location = useLocation();

  useEffect(() => {
    window?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <Layout hasSider>
      <Sider width={250} $colorBg={colorBgContainer}>
        <LogoWrapper>
          <img src={logo} alt='logo' />
        </LogoWrapper>
        <SideMenu />
      </Sider>
      <Body>
        <Header />
        <Content>
          <Outlet />
        </Content>
        <Footer>Autoterminal Japan</Footer>
      </Body>
    </Layout>
  );
};

const Sider = styled(Layout.Sider) <StyledProps>`
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  height: 100vh;
  background: ${(props) => props.$colorBg} !important;
`;

const LogoWrapper = styled.div`
  margin: 15px 0 20px;
  height: 60px;
  text-align: center;

  img {
    height: 100%;
  }
`;

const Body = styled(Layout)`
  margin-left: 250px;
`;

const Content = styled(Layout.Content)`
  margin: 24px 16px 0;
  overflow: initial;
  min-height: calc(100vh - (24px + 45px + 82px));
`;

const Footer = styled(Layout.Footer)`
  text-align: center;
`;

export default MainLayout;
