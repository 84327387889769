import { getPermissionLevels } from '@/helpers/permissionsHelper';

import type {
  GetPermissionRes,
  GetPermissionApiRes,
  GetMyPermissionRes,
  GetMyPermissionsApiRes,
  GetRoleRes,
  GetRoleApiRes,
  GetRoleByIdRes,
  GetRoleByIdApiRes,
} from './types';
import { UpdateRoleReq } from './types';

export const getPermissionsRes = (response: GetPermissionApiRes): GetPermissionRes => {
  return response.data.sort((a, b) => a.localeCompare(b));
};

export const getRolesRes = (response: GetRoleApiRes): GetRoleRes => {
  return {
    meta: {
      current: response.data.page,
      pageSize: response.data.limit,
      total: response.data.total_rows,
    },
    results: response.data.rows.sort((a, b) => a.id - b.id),
  };
};

export const getRolesByIdRes = (response: GetRoleByIdApiRes): GetRoleByIdRes => {
  return {
    result: response.data,
  };
};

export const updateRoleReq = ({ body }: UpdateRoleReq) => {
  const payload: any = {
    role: body.role,
    permissions: body.permissions,
    status: body.status,
  };

  if (body.id) {
    payload.id = body.id;
  }

  return payload;
};

export const getMyPermissionsRes = (response: GetMyPermissionsApiRes): GetMyPermissionRes => {
  const { permissions } = response.data;

  return getPermissionLevels(permissions).sort((a, b) => a.localeCompare(b));
};
