import service from './service';

export const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,

  getCertificatesMeta: service.useGetCertificatesMetaQuery,
  getFacilities: service.useGetFacilitiesQuery,
  getRoles: service.useGetRolesQuery,
};

export default serviceConfig;
