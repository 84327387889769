import { FC } from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Layout, Dropdown, theme, Switch } from 'antd';
import styled from 'styled-components';
import { StyledProps } from '@/types';
import { useHeaderHandler } from './handler';

const Header: FC = () => {
  const { colorBgContainer } = theme.useToken().token;
  const { userName, appTheme, toggleTheme, handleLogout, handleNavigateProfile } = useHeaderHandler();

  const items: MenuProps['items'] = [
    {
      label: 'Profile',
      key: 'profile',
      icon: <UserOutlined />,
      onClick: handleNavigateProfile,
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];

  return (
    <HeaderWrapper $colorBg={colorBgContainer}>
      <HeaderItem>
        <Switch size='small' checked={appTheme === 'dark'} checkedChildren={'🌞'} unCheckedChildren={'🌜'} onChange={toggleTheme} />
      </HeaderItem>
      <HeaderItem>
        <Dropdown placement='bottomRight' menu={{ items }} trigger={['click']}>
          <SelectAvatar size='small'>{userName}</SelectAvatar>
        </Dropdown>
      </HeaderItem>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled(Layout.Header)<StyledProps>`
  position: sticky !important;
  top: 0;
  z-index: 1;
  padding: 5px 20px 5px 5px;
  height: 45px;
  line-height: 1;
  background: ${(props) => props.$colorBg} !important;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const HeaderItem = styled.div`
  margin: 0 10px;
`;

const SelectAvatar = styled(Avatar)`
  cursor: pointer;
`;

export default Header;
