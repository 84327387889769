const config = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  },
};

export default config;
