import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@/app/store';
import config from '@/config';
import { bindToken, buildQueryParams } from '@/services/utils';
import { createReq, getAllRes, getByIdRes, getProfileRes, updateReq, resetPasswordReq } from './service-mapping';

import type {
  CreateReq,
  CreateRes,
  GetAllReq,
  GetAllRes,
  GetAllApiRes,
  GetByIdReq,
  GetByIdRes,
  GetByIdApiRes,
  GetProfileReq,
  GetProfileRes,
  GetProfileApiRes,
  UpdateReq,
  UpdateRes,
  ResetPasswordReq,
  ResetPasswordRes,
  ToggleStatusReq,
  ToggleStatusRes,
} from './types';

const service = createApi({
  reducerPath: 'user-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders: (headers, { endpoint, getState }) => {
      bindToken(['create', 'getAll', 'getById', 'getProfile', 'update', 'resetPassword', 'toggleStatus'], endpoint, headers, getState() as RootState);
    },
  }),
  tagTypes: ['getAll'],
  endpoints: (builder) => ({
    create: builder.mutation<CreateRes, CreateReq>({
      query: (args) => {
        const payload = createReq(args);
        return {
          url: `/admin/user/`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    getAll: builder.query<GetAllRes, GetAllReq>({
      query: ({ params, pagination }) => {
        const queryParams = buildQueryParams({ pagination });
        const tab = params.tab !== 'all' ? `/${params.tab}` : '';

        return `/admin/user/type/internal${tab}?${queryParams}`;
      },
      transformResponse: (res: GetAllApiRes, meta, args) => {
        return getAllRes(res, args);
      },
      providesTags: ['getAll'],
      keepUnusedDataFor: 0,
    }),
    getById: builder.query<GetByIdRes, GetByIdReq>({
      query: ({ params }) => {
        const { localId } = params;
        return `/admin/user/${localId}`;
      },
      transformResponse: (res: GetByIdApiRes) => {
        return getByIdRes(res);
      },
      keepUnusedDataFor: 0,
    }),
    getProfile: builder.query<GetProfileRes, GetProfileReq>({
      query: () => '/profile/',
      transformResponse: (res: GetProfileApiRes) => {
        return getProfileRes(res);
      },
      keepUnusedDataFor: 0,
    }),
    update: builder.mutation<UpdateRes, UpdateReq>({
      query: (args) => {
        const payload = updateReq(args);
        return {
          url: `/admin/user/`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    resetPassword: builder.mutation<ResetPasswordRes, ResetPasswordReq>({
      query: (args) => {
        const payload = resetPasswordReq(args);

        return {
          url: `/admin/user/password/reset`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    toggleStatus: builder.mutation<ToggleStatusRes, ToggleStatusReq>({
      query: ({ params }) => ({
        url: `/admin/user/status/toggle/${params.localId}`,
        method: 'POST',
      }),
      invalidatesTags: ['getAll'],
    }),
  }),
});

const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,

  create: service.useCreateMutation,
  getAll: service.useGetAllQuery,
  getById: service.useGetByIdQuery,
  getProfile: service.useGetProfileQuery,
  update: service.useUpdateMutation,
  resetPassword: service.useResetPasswordMutation,
  toggleStatus: service.useToggleStatusMutation,
};

export default serviceConfig;
