import { Form } from 'antd';

export const useHandler = () => {
  const [form] = Form.useForm();

  const handleReset = () => {
    form.resetFields();
  };

  const handleSubmit = () => {};

  return {
    form,

    handleReset,
    handleSubmit,
  };
};
