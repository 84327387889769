import { FC } from 'react';
import { Button, Card, Col, Form, Row, Space, theme } from 'antd';
import { StyledProps } from '@/types';
import { CForm } from '@/components';
import styled from 'styled-components';
import type { CFormItemProps } from '../types';
import { useHandler } from './handler';

interface FilterProps {
  items: Array<CFormItemProps>;
  handleSubmit: (data: any) => void;
}

const Filter: FC<FilterProps> = ({ items, handleSubmit }) => {
  const { form, handleReset } = useHandler();
  const { colorFillQuaternary } = theme.useToken().token;

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <FilterWrapper $colorBg={colorFillQuaternary}>
        <Form form={form} layout='vertical' labelCol={{ span: 9 }} onFinish={handleSubmit}>
          <Row gutter={[24, 0]} justify='space-between' align='middle'>
            {items.map((props, key) => (
              <CForm.FormItem allowClear key={key} {...props} />
            ))}
            <Actions>
              <Space>
                <Button onClick={handleReset}>Clear</Button>
                <Button type='primary' htmlType='submit'>
                  Search
                </Button>
              </Space>
            </Actions>
          </Row>
        </Form>
      </FilterWrapper>
    </Card>
  );
};

const FilterWrapper = styled.div<StyledProps>`
  padding: 20px 25px;
  background: ${(props) => props.$colorBg};
`;

const Actions = styled(Col)`
  margin-left: auto;
`;

export default Filter;
