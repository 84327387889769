import { FC, ReactNode } from 'react';
import { Col, ColProps, Row } from 'antd';
import styled from 'styled-components';
import logo from '@/assets/atj-logo.png';

interface PageProps {
  size: ColProps;
  children: ReactNode;
}

const FreePage: FC<PageProps> = ({ size, children }) => (
  <Container justify='space-around' align='middle'>
    <Col {...size}>
      <LogoWrapper>
        <Logo src={logo} alt='AutoTerminal - Japan' />
      </LogoWrapper>
      <Content>{children}</Content>
    </Col>
  </Container>
);

const Container = styled(Row)`
  min-height: 90vh;
`;
const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const Logo = styled.img`
  width: 150px;
`;

const Content = styled.div`
  margin-top: 10px;
`;

export default FreePage;
