import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setTheme, selectUser, selectTheme } from '@/slices/session';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useAuth } from '@/features/auth/hooks';

export const useHeaderHandler = () => {
  const dispatch = useAppDispatch();
  const { initials: userName } = useAppSelector(selectUser) || {};
  const appTheme = useAppSelector(selectTheme);
  const navigate = useNavigate();
  const { logoutSuccess, logout } = useAuth();

  const toggleTheme = (checked: boolean) => {
    dispatch(setTheme(checked ? 'dark' : 'light'));
  };

  const handleLogout = () => {
    logout();
  };

  const handleNavigateProfile = () => {
    navigate('/user/profile');
  };

  useEffect(() => {
    if (logoutSuccess) {
      navigate('/login');
    }
  }, [logoutSuccess, navigate]);

  return {
    userName,
    appTheme,

    toggleTheme,
    handleLogout,
    handleNavigateProfile,
  };
};
