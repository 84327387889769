export const isPermitted = (permissions: string[], scope: string[]) => {
  if (!permissions.length) {
    return true;
  }

  return scope.some((permission) => permissions.includes(permission));
};

export const getPermissionLevels = (permissions: string[]) => {
  const permissionLevels = new Set();

  permissions.forEach((permission) => {
    const levels = permission.split('.');
    levels.reduce((acc, curr) => {
      const level = acc ? `${acc}.${curr}` : curr;
      permissionLevels.add(level);
      return level;
    }, '');
  });

  return Array.from(permissionLevels) as string[];
};

export const getRuntimeScope = (scope: string[], params: { [key: string]: string }) => {
  return scope.map((item) => {
    let replacedItem = item;
    const keys = item.match(/:(\w+)/g);

    if (keys) {
      keys.forEach((key) => {
        const paramKey = key.substring(1);
        if (params[paramKey]) {
          replacedItem = replacedItem.replace(key, params[paramKey]);
        }
      });
    }

    return replacedItem;
  });
};
