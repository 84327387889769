import { getDateStr } from '@/services/utils';

import type {
  GetAllReq,
  GetAllRes,
  GetAllApiRes,
  GetByIdRes,
  GetByIdApiRes,
  UpdateReq,
  ExportFileReq,
  ImportFileRes,
  ImportFileApiRes,
  ImportConfirmReq,
} from './types';

export const getAllReq = ({ query }: GetAllReq) => {
  const queryParams: any = {};

  queryParams.chn = query.chassisNo;
  queryParams.dmf = query.damageFlag;

  return queryParams;
};
export const getAllRes = (response: GetAllApiRes): GetAllRes => {
  return {
    meta: {
      current: response.data.page,
      pageSize: response.data.limit,
      total: response.data.total_rows,
    },
    results: response.data.rows.map((row) => ({
      uuid: row.uuid,
      inspectorId: row.inspector_id,
      inspectionDate: row.inspection_date,
      make: row.make,
      model: row.model,
      chassisNo: row.chassis_no,
      importerName: row.importer_name,
      damageFlag: row.damage_flag,
    })),
  };
};

export const getByIdRes = (response: GetByIdApiRes): GetByIdRes => {
  return {
    result: {
      uuid: response.data.uuid,
      createdCountry: response.data.created_country,

      inspectorId: response.data.inspector_id,
      inspectionDate: response.data.inspection_date,

      importerName: response.data.importer_name,
      importerAddress: response.data.importer_address,
      countryImport: response.data.country_of_import,
      vesselName: response.data.vessel_name,
      portLoading: response.data.port_of_loading,
      portArrival: response.data.port_of_arrival,

      leftHandDrive: response.data.left_hand_drive,

      make: response.data.make,
      model: response.data.model,
      chassisNo: response.data.chassis_no,
      borderOdometer: response.data.border_odometer,
      borderOdometerUnit: response.data.border_odometer_unit,

      structuralDamage: response.data.structural_damage,
      srsDamage: response.data.srs_damage,
      fireDamage: response.data.fire_damage,
      waterDamage: response.data.water_damage,
      damageNotes: response.data.damage_notes,

      damageFlag: response.data.damage_flag,
    },
  };
};

export const updateReq = ({ body }: UpdateReq) => {
  return {
    created_country: body.createdCountry,

    inspector_id: body.inspectorId,
    inspection_date: getDateStr(body.inspectionDate),

    importer_name: body.importerName,
    importer_address: body.importerAddress,
    country_of_import: body.countryImport,
    vessel_name: body.vesselName,
    port_of_loading: body.portLoading,
    port_of_arrival: body.portArrival,

    left_hand_drive: body.leftHandDrive,

    make: body.make,
    model: body.model,
    chassis_no: body.chassisNo,
    border_odometer: Number(body.borderOdometer),
    border_odometer_unit: body.borderOdometerUnit,

    structural_damage: body.structuralDamage,
    srs_damage: body.srsDamage,
    fire_damage: body.fireDamage,
    water_damage: body.waterDamage,
    damage_notes: body.damageNotes,
  };
};

export const exportFileReq = ({ body }: ExportFileReq) => {
  const inspectionDate = body.inspectionDate;

  const df = inspectionDate ? getDateStr(inspectionDate?.[0]) : undefined;
  const dt = inspectionDate ? getDateStr(inspectionDate?.[1]) : undefined;

  return {
    crn: body.corNo,
    chn: body.chassisNo,
    df,
    dt,
  };
};

export const importFileRes = (response: ImportFileApiRes): ImportFileRes => {
  return {
    result: {
      fileId: response.data.file_id,
      importType: response.data.import_type,
      uniqueIdentifierColumn: response.data.unique_identifier_column,
      requiredColumns: response.data.required_columns,
      columnMapping: response.data.column_mapping,
      country: response.data.country,
      dataErrors: response.data.data_errors,
      duplicates: response.data.duplicates,
      duplicateCheck: response.data.duplicate_check,
      sampleData: response.data.sample_data,
    },
  };
};

export const importConfirmReq = ({ body }: ImportConfirmReq) => {
  return {
    file_id: body.fileId,
    import_type: body.importType,
    unique_identifier_column: body.uniqueIdentifierColumn,
    required_columns: body.requiredColumns,
    column_mapping: body.columnMapping,
    sample_data: body.sampleData,
    data_errors: body.dataErrors,
    duplicates: body.duplicates,
    duplicate_check: body.duplicateCheck,
    country: body.country,
  };
};
