import { FC, ReactNode } from 'react';
import type { ColProps } from 'antd';
import { Card, Col, Row, Typography } from 'antd';
import styled from 'styled-components';

interface FormGroupProps {
  label: string;
  size: ColProps;
  children: ReactNode;
}

const FormGroup: FC<FormGroupProps> = ({ label, size, children }) => {
  return (
    <Col {...size}>
      <Wrapper type='inner'>
        <Label>{label}</Label>
        <Row>{children}</Row>
      </Wrapper>
    </Col>
  );
};

const Wrapper = styled(Card)`
  margin: 5px;
  border-radius: 8px;
`;

const Label = styled(Typography.Text)`
  margin: 10px 0 10px -5px;
  display: block;
  font-weight: 500;
`;

export default FormGroup;
