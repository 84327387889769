import type {
  CreateReq,
  GetAllReq,
  GetAllApiRes,
  GetByIdRes,
  GetByIdApiRes,
  GetProfileRes,
  GetProfileApiRes,
  UpdateReq,
  ResetPasswordReq,
} from './types';

export const createReq = ({ body }: CreateReq) => {
  const payload: any = {
    name: body.name,
    email: body.email,
    password: body.password,
    company_name: body.companyName,
    telephone: body.telephone,
    country: body.country,
    role: body.role,
    branch_id: body.branchId,
    two_factor_auth: body.twoFactorAuth,
    ip_validation: body.ipValidation,
  };

  if (body.ipValidation) {
    payload.ip_type = body.ipType;
    payload.ip_address = body.ipAddress;
    payload.ip_from = body.ipFrom;
    payload.ip_to = body.ipTo;
  }

  return payload;
};

export const getAllRes = (response: GetAllApiRes, args: GetAllReq) => {
  return {
    meta: {
      current: response.data.page,
      pageSize: response.data.limit,
      total: response.data.total_rows,
    },
    results: response.data.rows.map((row) => ({
      id: row.id,
      localId: row.local_id,
      name: row.name,
      email: row.email,
      companyName: row.company_name,
      roleName: row.role_name,
      country: row.country,
      active: row.active,
    })),
  };
};

export const getByIdRes = (response: GetByIdApiRes): GetByIdRes => {
  return {
    result: {
      id: response.data.id,
      localId: response.data.local_id,
      name: response.data.name,
      email: response.data.email,
      companyName: response.data.company_name,
      telephone: response.data.telephone,
      country: response.data.country,
      role: response.data.role,
      roleName: response.data.role_name,
      branchId: response.data.branch_id,
      branchName: response.data.branch_name,
      twoFactorAuth: response.data.two_factor_auth,
      ipValidation: response.data.ip_validation,
      ipType: response.data.ip_type,
      ipAddress: response.data.ip_address,
      ipFrom: response.data.ip_from,
      ipTo: response.data.ip_to,
      active: response.data.active,
    },
  };
};

export const getProfileRes = (response: GetProfileApiRes): GetProfileRes => getByIdRes(response);

export const updateReq = ({ body }: UpdateReq) => {
  const payload: any = {
    id: body.id,
    local_id: body.localId,
    name: body.name,
    email: body.email,
    company_name: body.companyName,
    telephone: body.telephone,
    country: body.country,
    role: body.role,
    branch_id: body.branchId,
    two_factor_auth: body.twoFactorAuth,
    ip_validation: body.ipValidation,
  };

  if (body.ipValidation) {
    payload.ip_type = body.ipType;
    payload.ip_address = body.ipAddress;
    payload.ip_from = body.ipFrom;
    payload.ip_to = body.ipTo;
  }

  return payload;
};

export const resetPasswordReq = ({ body }: ResetPasswordReq) => {
  return {
    local_id: body.localId,
    password: body.password,
  };
};
