import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@/app/store';
import config from '@/config';
import { fileService } from '@/services';
import { bindToken, buildQueryParams } from '@/services/utils';
import {
  createReq,
  getAllReq,
  getAllRes,
  getByIdRes,
  updateReq,
  generateCertificatesRes,
  getCertificates,
  exportFileReq,
  importFileRes,
  importConfirmReq,
} from './service-mapping';

import type {
  CreateReq,
  CreateRes,
  GetAllReq,
  GetAllRes,
  GetAllApiRes,
  GetByIdReq,
  GetByIdRes,
  GetByIdApiRes,
  UpdateReq,
  UpdateRes,
  DeleteMultipleReq,
  DeleteMultipleRes,
  GenerateCertificatesReq,
  GenerateCertificatesRes,
  GenerateCertificatesApiRes,
  GetCertificatesReq,
  GetCertificatesRes,
  GetCertificatesApiRes,
  ExportFileReq,
  ImportFileReq,
  ImportFileRes,
  ImportFileApiRes,
  ImportConfirmReq,
  ImportConfirmRes,
} from './types';

const service = createApi({
  reducerPath: 'rwi-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders: (headers, { endpoint, getState }) => {
      bindToken(
        ['create', 'getAll', 'getById', 'update', 'deleteMultiple', 'generateCertificates', 'getCertificates', 'importFile', 'importConfirm'],
        endpoint,
        headers,
        getState() as RootState
      );
    },
  }),
  tagTypes: ['getAll', 'getById', 'getCertificates'],
  endpoints: (builder) => ({
    create: builder.mutation<CreateRes, CreateReq>({
      query: (args) => {
        const { origin, destination } = args.params;
        const payload = createReq(args);

        return {
          url: `/rwi/${destination}/${origin}`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    getAll: builder.query<GetAllRes, GetAllReq>({
      query: (args) => {
        const { params, pagination } = args;
        const query = getAllReq(args);
        const { origin, destination } = params;
        const queryParams = buildQueryParams({ query, pagination });

        return `/rwi/${destination}/${origin}?${queryParams}`;
      },
      transformResponse: (res: GetAllApiRes, meta, args) => {
        return getAllRes(res, args);
      },
      providesTags: ['getAll'],
      keepUnusedDataFor: 0,
    }),
    getById: builder.query<GetByIdRes, GetByIdReq>({
      query: ({ params }) => {
        const { origin, destination, id } = params;
        return `/rwi/${destination}/${origin}/${id}`;
      },
      transformResponse: (res: GetByIdApiRes, meta, args) => {
        return getByIdRes(res, args);
      },
      providesTags: (_, __, { params }) => [{ type: 'getById', id: params.id }],
      keepUnusedDataFor: 0,
    }),
    update: builder.mutation<UpdateRes, UpdateReq>({
      query: (args) => {
        const { origin, destination, id } = args.params;
        const payload = updateReq(args);

        return {
          url: `/rwi/${destination}/${origin}/${id}`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
    deleteMultiple: builder.mutation<DeleteMultipleRes, DeleteMultipleReq>({
      query: ({ params, body }) => {
        const { origin, destination } = params;
        return {
          url: `/rwi/${destination}/${origin}/multi`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['getAll'],
    }),
    generateCertificates: builder.mutation<GenerateCertificatesRes, GenerateCertificatesReq>({
      query: ({ params, body }) => ({
        url: `/rwi/${params.destination}/${params.origin}/certificates/${params.certificateType}`,
        method: 'POST',
        body: body.ids,
      }),
      transformResponse: (res: GenerateCertificatesApiRes, meta, args) => {
        return generateCertificatesRes(res, args);
      },
      onQueryStarted: async ({ body }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        const tags = body.ids.reduce((acc: any, current) => {
          return acc.concat(['getAll', { type: 'getById', id: current }, { type: 'getCertificates', id: current }]);
        }, []);

        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(service.util.invalidateTags(tags));
      },
    }),
    getCertificates: builder.query<GetCertificatesRes, GetCertificatesReq>({
      query: (args) => {
        const { origin, destination, id } = args.params;
        return `/rwi/${destination}/${origin}/${id}/certificates`;
      },
      transformResponse: (res: GetCertificatesApiRes, meta, args) => {
        return getCertificates(res, args);
      },
      providesTags: (_, __, { params }) => [{ type: 'getCertificates', id: params.id }],
      keepUnusedDataFor: 0,
    }),
    importFile: builder.mutation<ImportFileRes, ImportFileReq>({
      query: (args) => {
        const { params, body } = args;
        return {
          url: `/generic/import/upload/${params.origin}?type=RWI`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (res: ImportFileApiRes, meta, args) => {
        return importFileRes(res, args);
      },
    }),
    importConfirm: builder.mutation<ImportConfirmRes, ImportConfirmReq>({
      query: (args) => {
        const payload = importConfirmReq(args);

        return {
          url: `/generic/import/${args.params.origin}`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['getAll'],
    }),
  }),
});

const useExportFileMutation = () => {
  const [action, status] = fileService.useExportFile();

  const call = (args: ExportFileReq) => {
    const filters = exportFileReq(args);
    const { origin, destination } = args.params;
    const fileName = `rwi-${origin}-${destination}-export-${new Date().toISOString()}.xlsx`;
    const payload = {
      method: 'POST',
      endpoint: `/rwi/${destination}/${origin}/export`,
      meta: {
        body: {
          af: filters,
        },
      },
      fileName,
    };

    action(payload);
  };

  return [call, status] as const;
};

const serviceConfig = {
  key: service.reducerPath,
  reducer: service.reducer,
  middleware: service.middleware,

  create: service.useCreateMutation,
  getAll: service.useGetAllQuery,
  getById: service.useGetByIdQuery,
  update: service.useUpdateMutation,
  deleteMultiple: service.useDeleteMultipleMutation,
  generateCertificates: service.useGenerateCertificatesMutation,
  getCertificates: service.useGetCertificatesQuery,
  exportFile: useExportFileMutation,
  importFile: service.useImportFileMutation,
  importConfirm: service.useImportConfirmMutation,
};

export default serviceConfig;
